import './src/styles/styles.scss';

function disquss() {
    /**
     *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
     *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables    */
  
    var disqus_config = function () {
      this.page.url = window.location.href;
      this.page.identifier = window.location.pathname; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    };
  
    // DON'T EDIT BELOW THIS LINE
    var d = document,
      s = d.createElement('script');
    s.src = 'https://www.playcasino.co.za.disqus.com/embed.js';
    s.id = 'disquss-code';
    s.setAttribute('data-timestamp', + new Date());
    (d.head || d.body).appendChild(s);
}

function scrollEvent(event) {
    const enableDisquss = window.location.pathname.endsWith('-review');
  
    if (
      typeof window !== 'undefined' &&
      !document.getElementById('disquss-code') &&
      enableDisquss
    ) {
      disquss();
    }
  
    if (event) {
      event.currentTarget.removeEventListener(event.type, scrollEvent); // remove the event listener that got triggered
    }
}

export const onClientEntry = () => {
    if (process.env.PPC === 'true') {
      scrollEvent(null);
    } else {
      document.addEventListener('scroll', scrollEvent);
      document.addEventListener('mousemove', scrollEvent);
      document.addEventListener('touchstart', scrollEvent, { passive: true });
    }
};